<template>
  <div>
    <div
      class="back_img"
      :style="{ backgroundImage: `url(${defaultBackImg})` }"
    >
      <a-input-search
        placeholder="输入你想要搜素的图片"
        style="
          position: absolute;
          top: 35%;
          left: 0;
          right: 0;
          margin: auto;
          width: 600px;
          font-size: 12px;
        "
        size="large"
        @search="onSearch"
      />
    </div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10px"
      style="display: flex; flex-wrap: wrap; margin: 0 auto; width: 80%"
    >
      <a-card
        :bordered="false"
        v-for="(img, index) of imgData"
        :key="index"
        style="
          width: 48%;
          height: 100%;
          margin: 1%;
          position: relative;
          cursor: pointer;
        "
        @click="handleShowImg(img)"
        :bodyStyle="{ padding: 0 }"
        @mouseover="over(index)"
        @mouseout="out"
      >
        <img
          slot="cover"
          alt="example"
          :src="img"
          style="width: 100%; border-radius: 10px"
        />
        <div
          v-show="showImgText === index ? true : false"
          style="
            height: 100%;
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
          "
        >
          <div style="display: flex; color: #fff">
            <img
              src="../assets/lightWorld/weizhi.png"
              style="width: 20px; margin-right: 10px"
            />
            <span> 海南省海口市XXX镇XXX旅游胜地 </span>
          </div>
          <div style="display: flex">
            <img
              src="https://imgapi.cn/qq.php?qq=1097925389"
              alt="头像"
              style="
                width: 50px;
                height: 50px;
                border-radius: 100%;
                margin-right: 10px;
              "
            />
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                color: #fff;
              "
            >
              <div
                style="
                  font-size: 16px;
                  font-family: Roboto, Roboto-Bold;
                  line-height: 16px;
                "
              >
                九亿少女的梦
              </div>
              <div>2022-04-15</div>
            </div>
          </div>
        </div>
      </a-card>
    </div>
    <div style="text-align: center; margin: 10px 0">
      <h1>加载中<a-spin size="large" style="margin-left: 20px" /></h1>
    </div>
    <a-modal
      v-model="modalVisible"
      :dialog-style="{ top: '30px' }"
      width="80%"
      :closable="false"
      :footer="null"
    >
      <div style="display: flex; height: 90vh">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 33%;
          "
        >
          <div style="display: flex">
            <img
              src="https://imgapi.cn/qq.php?qq=1097925389"
              alt="头像"
              style="
                width: 50px;
                height: 50px;
                border-radius: 100%;
                margin-right: 10px;
              "
            />
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
              "
            >
              <div
                style="
                  font-size: 16px;
                  font-family: Roboto, Roboto-Bold;
                  font-weight: 700;
                  color: #333333;
                  line-height: 16px;
                "
              >
                九亿少女的梦
              </div>
              <div>2022-04-15</div>
            </div>
          </div>
          <div style="display: flex">
            <img
              src="../assets/lightWorld/weizhi.png"
              style="width: 20px; margin-right: 10px"
            />
            <span> 海南省海口市XXX镇XXX旅游胜地 </span>
          </div>
        </div>
        <div style="width: 34%">
          <img
            :src="showImg"
            style="width: 100%; height: 100%; border-radius: 10px"
          />
        </div>
        <div
          style="
            display: flex;
            width: 33%;
            justify-content: flex-end;
            align-items: flex-start;
          "
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <a-button
              icon="download"
              style="
                width: 45px;
                height: 32px;
                margin-right: 50px;
                border-radius: 5px;
              "
            />
            <img
              src="../assets/lightWorld/smallMa.png"
              style="width: 32px; height: 32px"
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { API_imgList } from "../util/map-source";
import { simpleDebounce } from "../util/util";
const imgData = ["https://imgapi.cn/api.php?fl=fengjing&gs=images"];
export default {
  name: "lightWorld",
  data() {
    return {
      count: 0,
      data: [],
      busy: false,
      imgData,
      defaultBackImg: "",
      modalVisible: false,
      showImg: "",
      showImgText: "",
    };
  },
  methods: {
    onSearch(value) {
      console.log(value);
    },
    //无限加载图片
    loadMore() {
      this.test();
    },
    test: simpleDebounce(async function () {
      this.busy = true;
      for (let i = 0; i < 5; i++) {
        this.imgData.push(imgData[0]);
      }
      // const data = await API_imgList("fengjing");
      // this.imgData.push(data.imgurl);
      this.busy = false;
    }, 1000),
    //点击弹出详细信息
    handleShowImg(img) {
      console.log(img);
      this.showImg = img;
      this.modalVisible = true;
    },
    //鼠标进入显示用户
    over(img) {
      this.showImgText = img;
    },
    //鼠标离开隐藏用户
    out() {
      this.showImgText = "";
    },
  },
  async mounted() {
    // const data = await API_imgList("fengjing");
    // this.defaultBackImg = data.imgurl;
    this.defaultBackImg = imgData[0];
  },
};
</script>
<style lang="scss" scoped>
.back_img {
  height: calc(100vw * 0.5625);
  min-height: 576px;
  background-size: 100% 100%;
  margin-bottom: 3%;
  position: relative;
  ::v-deep .ant-input-lg {
    height: 45px;
    font-size: 14px;
  }
}
</style>
